<template>
  <div class="container">
    <section class="top">
      <div class="row">
        <div class="cols-12">
          <h4>Política de Segurança da Informação</h4>
          <br />
          <br />

          <p>
            <b>1. Objetivos</b>
          </p>
          <p>
            A Política de Segurança da Informação (Política) da empresa WEBZI
            PESQUISA & INOVAÇÃO TECNOLÓGICA, CNPJ: 37.552.169/0001-64,
            proprietária da Plataforma EBL Online, visa garantir a proteção, a
            manutenção da privacidade, integridade, disponibilidade e
            confidencialidade das informações de sua propriedade e/ou sob sua
            guarda, além de prevenir, detectar e reduzir a vulnerabilidade a
            incidentes relacionados com o ambiente cibernético, definindo as
            regras que representam, em nível estratégico, os princípios
            fundamentais incorporados pela Empresa para o alcance dos objetivos
            de segurança da informação.
          </p>
          <p>
            Esta Política abrange toda e qualquer tipo de informação, inclusive
            “não digitais”, cujo a guarda não é de forma digital. Os negócios da
            Empresa são altamente dependentes da Informação, fazendo com que a
            Segurança da Informação seja uma prioridade. Essa Política demonstra
            o compromisso da Empresa e de sua Alta Administração em zelar e
            tratar as informações de seus clientes, de forma a proporcionar
            plena satisfação quanto à segurança e privacidade de suas
            informações. Demonstramos também o nosso compromisso com os valores
            corporativos, aspectos regulatórios, e estratégicos da Empresa,
            estando assim, em conformidade com as principais regulamentações
            vigentes. A Política de Segurança da Informação é de cumprimento
            obrigatório por parte de todos os colaboradores da Empresa e é
            compatível com o porte, o perfil de risco e o modelo de negócio da
            Empresa, a natureza das operações e a complexidade dos produtos,
            serviços, atividades e processos da Empresa e a sensibilidade dos
            dados e das informações sob responsabilidade da Instituição.
          </p>

          <p>
            <b>2. Vigência </b>
          </p>
          <p>
            Esta Política deve ser revisada anualmente ou, quando necessário,
            caso haja alguma mudança nas normas da Empresa, alteração de
            diretrizes de segurança da informação, objetivos de negócio ou se
            requerido pelo regulador.
          </p>
          <p>
            <b>3. Aplicação </b>
          </p>
          <p>
            A Política de Segurança da Informação é aplicável a todas as áreas e
            colaboradores (funcionários, estagiários e terceiros) da WEBZI
            PESQUISA & INOVAÇÃO TECNOLÓGICA. Terceiros em uso das informações e
            de ativos da Empresa também devem estar em conformidade para com as
            diretrizes desta Política. Como “Terceiros” estão incluídos, entre
            outros: Prestadores de serviços de apoio e manutenção, Prestadores
            de serviços de operações relacionadas a sistemas de TI, serviços de
            coleta de dados, operações de atendimento, Clientes, Consultores,
            Auditores, Desenvolvedores terceirizados, fornecedores externos de
            serviços de TI e para outras áreas, pessoal temporário, estagiários
            e outros contratados de curta duração, pessoal de limpeza e outros
            serviços de apoio terceirizados.
          </p>
          <p>
            <b>4. Princípio de Segurança da Informação </b>
          </p>
          <p>
            Os ativos de informação são os bens mais importantes da WEBZI
            PESQUISA & INOVAÇÃO TECNOLÓGICA, portanto, tratá-los com
            responsabilidade é o compromisso da Empresa. Dessa forma, estamos
            fundamentados nos princípios de segurança da informação, cujo
            objetivos constituem a preservação da propriedade da informação,
            notadamente sua confidencialidade, integridade e disponibilidade,
            permitindo o uso e compartilhamento de forma controlada, bem como o
            monitoramento e tratamento de incidentes provenientes de ataques
            cibernéticos.
          </p>
          <p>
            <b>Confidencialidade:</b> garantir que as informações tratadas sejam
            de conhecimento exclusivo de pessoas especificamente autorizadas;
          </p>
          <p>
            <b>Integridade:</b> garantir que as informações sejam mantidas
            íntegras, sem modificações indevidas – acidentais ou propositais;
          </p>
          <p>
            <b>Disponibilidade:</b> garantir que as informações estejam
            disponíveis a todas as pessoas autorizadas a tratá-las.
          </p>
          <p>
            <b>5. Princípios da Política </b>
          </p>
          <ol type="a">
            <li>
              A segurança da informação cobre todos os aspectos da Organização e
              é uma parte inseparável das operações rotineiras, da segurança
              corporativa, da gestão de riscos e dos controles internos da WEBZI
              PESQUISA & INOVAÇÃO TECNOLÓGICA. Informações digitais e não
              digitais.
            </li>
            <li>
              Um aspecto importante e atual da segurança da informação é a
              privacidade de dados, tema este abordado na Lei 13.709 de 2018,
              que dispõe sobre a proteção e dados pessoais.
            </li>
            <li>
              A informação produzida ou recebida como resultado da atividade
              profissional dos colaboradores pertence à Empresa e/ou a seus
              clientes e parceiros.
            </li>
            <li>
              Divulgar informações confidenciais, restritas e estratégicas é
              crime previsto nas leis de propriedade intelectual e industrial
              (Lei nº 9279 de 1996) e de direitos autorais (Lei nº 9610 de
              1998).
            </li>
            <li>
              A segurança da informação depende de processos gerenciais de
              controle e sistemas.
            </li>
          </ol>
          <p>
            No entanto, todas as pessoas são diretamente responsáveis pela
            segurança das informações que acessam e manipulam. Sem o total
            comprometimento das pessoas com a segurança da informação, mesmo
            sistemas e processos gerenciais bem estruturados não podem prover um
            nível adequado de segurança.
          </p>

          <p>
            <b>6. Classificação da informação </b>
          </p>
          <p>
            Pelo fato da Empresa armazenar informações de seus clientes,
            fornecedores e parceiros de negócios, a responsabilidade da
            companhia em proteger estas informações é maior pois qualquer
            divulgação não autorizada pode expor a Empresa à riscos financeiros,
            legais, de imagem ou operacionais. Para que um processo de segurança
            da informação funcione corretamente, é necessário que as informações
            sejam devidamente classificadas e de acordo com essa classificação
            sejam implementados o nível de segurança adequado.
          </p>
          <p>
            A informação é classificada de forma a se indicar a
            confidencialidade e o nível esperado de proteção, conforme esta
            classificação. A informação possui diferentes níveis de
            sensibilidade e criticidade. Na Empresa adotamos os seguintes níveis
            de classificação das informações:
          </p>
          <p>
            Níveis de classificação da informação da WEBZI PESQUISA & INOVAÇÃO
            TECNOLÓGICA:
          </p>
          <p>
            <b>Pública (ou sem classificação):</b> a informação pode ser
            divulgada fora da Organização
          </p>
          <p>
            <b>Restrita:</b> pode ser utilizada internamente, mas requer
            aprovação para divulgação externa ou uso por outras áreas internas.
          </p>
          <p>
            <b>Confidencial:</b> informação sensível ao negócio, tratada com
            medidas adicionais de segurança. Só pode ser divulgada a pessoas
            autorizadas. A Empresa poderá revelar as informações confidenciais
            nas seguintes hipóteses: Sempre que estiver obrigado a revelá-las,
            seja em virtude de dispositivo legal, ato de autoridade competente,
            ordem ou mandado judicial; Aos órgãos de proteção e defesa de
            crédito e prestadores de serviços autorizados pela empresa a
            defender seus direitos e créditos; Aos órgãos reguladores do mercado
            financeiro.
          </p>
          <p>
            <b>7. Estrutura de gerenciamento de segurança da informação </b>
          </p>
          <p>
            O gerenciamento dos controles de segurança visa assegurar que os
            procedimentos operacionais sejam desenvolvidos, implantados e
            mantidos ou modificados de acordo com os objetivos estabelecidos
            nesta Política.
          </p>
          <p>
            <b>7.1 Gestão de acesso às informações</b> Os acessos às informações
            são controlados, monitorados, restringidos à menor permissão e
            privilégios possíveis, revistos periodicamente, e cancelados ao
            término do contrato de trabalho do colaborador ou do prestador de
            serviço. Os equipamentos e instalações de processamento de
            informação crítica ou sensível são mantidos em áreas seguras, com
            níveis de controle de acesso apropriados, incluindo proteção contra
            ameaças físicas e ambientais. Os colaboradores e terceiros da
            Empresa são treinados, periodicamente, sobre os conceitos de
            segurança da informação, através de um programa de conscientização e
            disseminação da cultura de segurança.
          </p>
          <p>
            <b>7.2 Proteção do ambiente tecnológico da Empresa</b> São
            estabelecidos controles e responsabilidades pela gestão e operação
            dos recursos de processamento das informações, visando garantir a
            segurança na infraestrutura tecnológica da Empresa por meio de um
            gerenciamento efetivo no monitoramento, tratamento e na resposta aos
            incidentes, com o objetivo de minimizar o risco de falhas e
            assegurar a administração segura das redes de comunicação.
          </p>
          <p>
            <b>7.2.1 Autenticação</b> O acesso às informações e aos ambientes
            tecnológicos da Empresa deve ser permitido apenas às pessoas
            autorizadas pelo Proprietário da Informação, levando em consideração
            o princípio do menor privilégio, a segregação de funções
            conflitantes e a classificação da informação. O controle de acesso
            aos sistemas deve ser formalizado e contemplar, no mínimo, os
            seguintes controles: • A utilização de identificadores (credencial
            de acesso) individualizados, monitorado e passíveis de bloqueios e
            restrições (automatizados e manuais); • A remoção de autorizações
            dadas a usuários afastados ou desligados da Empresa, ou ainda, que
            tenham mudado de função; e • A revisão periódica das autorizações
            concedidas.
          </p>
          <p>
            <b>7.2.2 Identificação de Incidentes de Segurança da Informação</b>
            São consideradas como incidentes de segurança da informação as
            situações e ocorrências que caracterizem a violação de qualquer
            diretriz e norma listadas na Política. A Empresa mantém
            procedimentos de reporte de incidentes de segurança, bem como,
            possui mecanismos de acionamento dos planos de continuidade de
            negócios em caso de desastres, tanto de origem digital como
            operacional. Caso um incidente de origem digital seja identificado
            pelo público geral, o mesmo deverá ser reportado pelo e-mail
            secutity.officer@eblonline.com.br
          </p>
          <p>
            <b>7.2.3 Prevenção a Vazamento de Informações</b> Utilização de
            software de controle DLP (“Data Loss Prevention”) para prevenção de
            vazamento de dados, responsável por garantir que dados confidenciais
            não seja, roubados, ou vazados na web por usuários não autorizados.
          </p>
          <p>
            <b>7.2.4 Testes de Intrusão</b> Testes de Intrusão (“PEN test”)
            interno e externo nas camadas de rede e aplicação devem ser
            realizados no mínimo anualmente.
          </p>
          <p>
            <b>7.2.5 Varredura de Vulnerabilidades</b> As varreduras das redes
            internas e externas devem ser executadas periodicamente. As
            vulnerabilidades identificadas devem ser tratadas e priorizadas de
            acordo com seu nível de criticidade.
          </p>
          <p>
            <b>7.2.6 Controle Contra Software Malicioso</b> Todos os ativos
            (computadores, servidores, etc.) que estejam conectados à rede
            corporativa ou façam uso de informações da Empresa, devem, sempre
            que compatível, ser protegidos com uma solução anti-malware
            determinada pela área de Segurança da Informação.
          </p>
          <p>
            <b>7.2.7 Criptografia</b> Toda solução de criptografia utilizada na
            Empresa deve seguir as regras de Segurança da Informação e os
            padrões de segurança dos Órgãos reguladores.
          </p>
          <p>
            <b>7.2.8 Rastreabilidade</b> Trilhas de auditoria automatizadas
            devem ser implantadas para todos os componentes de sistema para
            reconstruir os seguintes eventos: Autenticação de usuários
            (tentativas válidas e inválidas); • Acesso a informações; • Ações
            executadas pelos usuários, incluindo criação ou remoção de dados dos
            sistemas.
          </p>
          <p>
            <b>7.2.9 Segmentação de Rede</b> Para solicitação de criação,
            alteração e exclusão de regras nos firewalls e ativos de rede, o
            requisitante deve encaminhar pedido à área de Segurança da
            Informação que fará a análise, aprovação e registro, enviando para
            que seja executada pela área de TI.
          </p>
          <p>
            <b>7.2.10 Desenvolvimento Seguro</b> A Empresa deve manter um
            conjunto de princípios (DevSecOps) para desenvolver sistemas de
            forma segura, garantindo que a segurança da informação seja
            projetada e implementada no ciclo de vida de desenvolvimento de
            sistemas. Os aspectos de segurança da informação estão envolvidos
            nas seguintes fases do Ciclo de Aquisição, Desenvolvimento e
            Manutenção de Software, a saber:
          </p>
          <ul>
            <li>● Conceituação e Planejamento</li>
            <li>● Análise e Design</li>
            <li>● Construção e Desenvolvimento</li>
            <li>● Homologação</li>
            <li>● Implantação.</li>
          </ul>
          <br />
          <p>
            <b>7.2.11 Cópias de Segurança (Backup)</b> O processo de execução de
            backups é realizado, periodicamente, nos sistemas de informação da
            Empresa, de forma a evitar a perda de dados diante da ocorrência de
            incidentes. As cópias de backup são criptografadas de modo a
            garantir a preservação do sigilo e da privacidade.
          </p>
          <p>
            <b>7.2.12 Inventário e controle de ativos (hardware e software)</b>
            Todos os ativos internos associados às informações e com recursos de
            processamento da informação devem ser identificados, possuir um
            responsável, mantido em um repositório com revisão periódica, bem
            como definida a classificação da informação sobre o ativo
            identificado. Toda e qualquer aquisição de um novo software ou
            serviço, incluindo aqueles de armazenamento e computação em nuvem,
            deve ser antes analisada pela área de Segurança da Informação, a fim
            de se apurar os requisitos de baseline para segurança e riscos
            cibernéticos.
          </p>
          <p>
            <b
              >7.2.13 Configuração segura de hardware e software em dispositivos
              móveis, desktops e servidores</b
            >
            São definidos procedimentos para aplicação de atualizações de
            segurança nos sistemas operacionais dos servidores e estações, bem
            como a atualização de versões dos equipamentos de infraestrutura de
            segurança e de rede, com o objetivo de prevenir e/ou responder aos
            incidentes de segurança. Devem ser estabelecidas regras para
            especificação dos requerimentos mínimos de configurações dos
            servidores e estações de trabalho, visando garantir a segurança da
            informação. ● Configuração segura de notebooks O uso do computador
            móvel é restrito às atividades relacionadas com os negócios /
            serviços da Empresa. O usuário do computador móvel deverá assinar
            Termo de Custódia do computador móvel, declarando-se responsável
            pelo equipamento e seus dados.
          </p>
          <p>
            <b>7.3 Continuidade dos Negócios</b> O processo de continuidade de
            negócios é implementado com o intuito de reduzir os impactos e
            perdas de ativos da informação após um incidente crítico a um nível
            aceitável, por meio do mapeamento de processos críticos, análise de
            impacto nos negócios e testes periódicos de recuperação de
            desastres. Incluem-se nesse processo, a continuidade de negócios
            relativos aos serviços contratados na nuvem e os testes previstos
            para os cenários de ataques cibernéticos.
          </p>
          <p>
            <b
              >7.4 Processamento, Armazenamento de dados e computação em nuvem
              (Apenas para Instituição de Pagamento)</b
            >
            Conforme a Circular 3.909/2018 e a Circular 3.969/2019 do Banco
            Central do Brasil, a Empresa assegura-se que a contratação de
            serviços relevantes de processamento, armazenamento de dados e de
            computação em nuvem deve ser previamente comunicada formalmente ao
            Banco Central do Brasil. Para os fins de aderência regulatória sobre
            a análise de riscos operacionais e cibernéticos, de continuidade de
            negócios, de confidencialidade, de disponibilização de informações
            ao Banco Central do Brasil, de resposta a Incidentes, dentre outros,
            deve-se contemplar o processo de due-diligence junto ao fornecedor
            do produto ou serviço contratado, previamente à formalização do
            contrato de prestação de serviços entre a contraparte (Empresa) e a
            contratada (Fornecedor). A contratação de serviços relevantes de
            processamento, armazenamento de dados e de computação em nuvem, bem
            como as alterações contratuais desses serviços, devem ser
            previamente comunicados ao Banco Central do Brasil, no mínimo,
            sessenta dias antes da contratação dos serviços ou das alterações
            contratuais.
          </p>
          <p>
            <b>8. Aderência à Política</b> Caso seja identificada uma conduta
            não aderente à política ou o seu descumprimento, a Empresa tomará as
            medidas legais, tecnológicas ou disciplinares necessárias de forma a
            manter a aderência a mesma. Data da última atualização: 14 de
            Fevereiro de 2022, ESTE DOCUMENTO REVOGA VERSÕES ANTERIORES
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.top {
  padding: 200px 20px 0;
}
</style>
